import React from 'react'
import LinkOrHref from './LinkOrHref'
import moment from 'moment'

const Concerts = ({ data }) => {
  const concertItems = data.edges

  return (
    <div className="concerts">
      {concertItems.length ? (
        <ul className="divide-y">
          {concertItems.map((item, i) => {
            const concert = item.node.concertsACF

            const { date, venue, details, location } = concert
            const _date = moment(date).format('MMMM D, YYYY')

            return (
              <li
                key={i}
                className="m-0 hover:bg-slate-100 transition duration-300"
              >
                <LinkOrHref url={concert.link} classes="list lg:flex px-5 py-6">
                  <div className="date">
                    <span>{_date}</span>
                  </div>
                  <div className="location">
                    <span>{location}</span>
                  </div>
                  <div className="venue">
                    <span>{venue}</span>
                  </div>
                  <div className="details">
                    <span>{details}</span>
                  </div>
                  <div className="tickets">
                    {(concert.link && <span>TICKETS</span>) || <div></div>}
                  </div>
                </LinkOrHref>
              </li>
            )
          })}
        </ul>
      ) : (
        <p>No shows at this time.</p>
      )}
    </div>
  )
}

export default Concerts
