import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../layouts'
import Seo from '../components/Seo'
import Concerts from '../components/Concerts'
import PageTitle from '../components/PageTitle'
import Canonical from '../components/Canonical'

const Tour = ({ data }) => {
  const page = data.page
  const concerts = data.concerts

  return (
    <Layout>
      <Seo title={page.title} />
      <Canonical slug={page.slug} />
      <PageTitle title={page.title} />
      <Concerts data={concerts} />
      <div dangerouslySetInnerHTML={{ __html: page.content }} />
    </Layout>
  )
}

export default Tour

export const pageQuery = graphql`
  query {
    page: wpPage(title: { eq: "tour" }) {
      id
      slug
      content
      title
    }
    concerts: allWpConcert(sort: { fields: concertsACF___date, order: ASC }) {
      edges {
        node {
          concertsACF {
            date
            details
            link
            location
            venue
          }
        }
      }
    }
  }
`
